@import "../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";

body {
  background: #f0f4f7;
}

.account-header {
  background-color: #fff;
  padding: 4px 5rem 0;
}
.account-header .account-site-logo {
  max-width: 138px;
}

.marketing-hero {
  position: relative;
  overflow: hidden;
  padding: 30px 0;
  background: url("images/MarketingHeroCobalt.svg") #096edb;
  background-position: center;
  background-size: 100% 800%;
  color: #fff;
  padding-left: 5rem;
  padding-right: 5rem;
}

.marketing-hero .title {
  padding-bottom: 0;
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2;
}
.marginx-auto {
  margin: 0 auto;
}
/**
 * @license
 *
 * Font Family: Supreme
 * Designed by: Jérémie Hornus, Ilya Naumoff
 * URL: https://www.fontshare.com/fonts/supreme
 * © 2021 Indian Type Foundry
 */

@font-face {
  font-family: "Supreme-Variable";
  src: url("./fonts/Supreme-Variable.woff2") format("woff2"),
    url("./fonts/Supreme-Variable.woff") format("woff");
  font-weight: 100 800;
  font-display: swap;
  font-style: normal;
}

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 800.0;
*
* available axes:

* 'wght' (range from 100.0 to 800.0)

*/

@font-face {
  font-family: "Supreme-VariableItalic";
  src: url("./fonts/Supreme-VariableItalic.woff2") format("woff2"),
    url("./fonts/Supreme-VariableItalic.woff") format("woff");
  font-weight: 100 800;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Industry";
  src: url("./fonts/Industry-Bold.woff2") format("woff2"),
    url("./fonts/Industry-Bold.woff") format("woff");
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

.grecaptcha-badge {
  width: 256px;
  height: 60px;
  display: block;
  transition: right 0.3s ease 0s;
  visibility: visible !important;
  bottom: 14px;
  right: -186px;
  box-shadow: grey 0px 0px 5px;
  border-radius: 2px;
  overflow: hidden;
  z-index: 1;
}

.marketo-embedded-form {
  max-width: 500px;
}

.mktoForm .mktoFormCol,
.mktoForm .mktoFieldWrap,
.mktoForm .mktoLabel,
.mktoForm .mktoOffset,
.mktoForm .mktoGutter {
  float: none !important;
}
.mktoForm .mktoGutter {
  height: 0 !important;
}

.mktoForm .mktoRequiredField label.mktoLabel {
  font-weight: normal !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.mktoForm .mktoRequiredField label.mktoLabel::after {
  content: " *";
}

/* important to make submit button full width */
.mktoForm div,
.mktoForm span,
.mktoForm label,
.mktoForm p {
  display: block;
}
.mktoForm .mktoHtmlText {
  padding-top: 3px;
}
.mktoField {
  font-weight: 300;
  display: block;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: hsla(201, 7%, 82%, var(--tw-border-opacity));
  border-radius: 0.375rem;
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  padding: 0.75rem !important;
}

.mktoForm .mktoAsterix {
  display: none !important;
}

/** Empty Marketo row between Country/State and Ts & Cs **/
.mktoFormRow:nth-child(5) {
  display: none;
}

.mktoFormRow:nth-child(6) {
  display: flex;
}

.mktoFormRow:nth-child(6) {
  margin-bottom: 20px;
}

/** Don't show asterisk for Ts & Cs **/
.mktoForm .mktoFormRow:nth-child(6) .mktoRequiredField label.mktoLabel::after {
  content: "";
}
.mktoForm .mktoFormRow:nth-child(6) .mktoFormCol a {
  color: #69a7ca;
}

label[for="termsandConditions_3874"] {
  position: absolute;
  left: 4px;
  top: 25px;
}

.mktoFieldWrap input[type="checkbox"] {
  opacity: unset !important;
  height: 25px !important;
  margin-right: 5px;
  margin-top: -2px;
}

.mktoCaptchaDisclaimer {
  display: none !important;
}

.mktoButton {
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0);
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: #1e91a2;
  width: 100% !important;
}
.mktoButton:hover {
  filter: brightness(90%);
}
.mktoButton.error-button {
  background-color: red !important;
}
.ba-panel-background {
  position: relative;
  width: 50%;
  background: linear-gradient(#ebebeb 0%, #cecece 100%);
}
.panel-background {
  position: relative;
  width: 50%;
}
.reg-gen-wrapper {
  background-color: #fff;
}
.bar-svg-wrapper {
  width: 75%;
  float: right;
  margin-top: 50px;
}
.dark-text {
  color: #221f1f;
}
.purple-background {
  background-color: #46397f;
  opacity: 95%;
}
.teal-background {
  background-color: #1e91a2;
}
.registration-hero {
  position: absolute;
}
.reg-error {
  max-width: 500px;
}

@media (max-width: 770px) {
  .registration-hero {
    position: static;
    background: linear-gradient(#ebebeb 0%, #cecece 100%);
  }
  .panel-right-content {
    margin-left: 2.5rem !important;
  }
  .ba-panel-background {
    display: none;
  }
  .panel-background {
    width: 100%;
  }
}

@media (max-width: 480px) {
  body {
    background: #fff;
  }
  .account-header {
    padding: 4px 1rem 0;
  }
  .marketing-hero {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .panel-left-content,
  .panel-right-content {
    margin-left: 0 !important;
  }
  .mktoField {
    font-weight: 300;
    display: block;
    width: 100%;
    --tw-border-opacity: 1;
    border-color: hsla(201, 7%, 82%, var(--tw-border-opacity));
    border-radius: 0.375rem;
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    padding: 1.2rem !important;
  }
  input[type="checkbox"],
  select.mktoField {
    padding: 0.75rem !important;
  }
  /* checkbox */
  .mktoFormRow:nth-child(7) {
    margin-bottom: 20px;
  }
}
.qrcode-error::before {
  content: " ";
  display: block;
  position: absolute;
  top: 70px;
  left: 33px;
  width: 100px;
  height: 20px;
  transform: rotate(-45deg);
  background-color: gray;
}
.qrcode-error::after {
  content: " ";
  display: block;
  position: absolute;
  top: 70px;
  left: 33px;
  width: 100px;
  height: 20px;
  transform: rotate(45deg);
  background-color: gray;
}
.qrcode-success::before {
  content: " ";
  display: block;
  width: 90px;
  height: 15px;
  transform: rotate(-45deg) translate(-25px, 85px) skew(-30deg);
  background-color: green;
}
.qrcode-success::after {
  content: " ";
  display: block;
  width: 40px;
  height: 15px;
  transform: rotate(75deg) translate(84px, -17px) skew(30deg);
  background-color: green;
}
.top-signout {
  border-width: 1px;
  border-radius: 9999px;
  padding: 0.5rem 1.25rem;
  margin-top: -7px;
}
.top-signout:hover {
  background-color: #1d90a1;
}

.text-cyan-normal {
  color: #1d90a1;
}
.text-cyan-normal:hover,
.text-cyan-dark {
  color: #1d90a1;
  filter: brightness(90%);
}

.logo-100 {
  width: 100px;
}
.logo-200 {
  width: 200px;
}
.mountain-man {
  background: url("../public/mountain-man.png");
  background-size: cover;
}
.login-wrapper {
  margin: auto;
}
.check-wrapper {
  background-color: #1e91a2;
  border-radius: 999999999px;
  padding: 3px;
}
.white-90 {
  background-color: rgba(255, 255, 255, 0.9);
}
.ba-grey-1 {
  color: #71757f;
}
.ba-grey-2 {
  color: #27292f;
}
.ba-grey-3 {
  color: #adafb3;
}
a.ba-button,
button.ba-button {
  background: #1e91a2;
  border-color: #1e91a2;
}
a.ba-button-grey-3,
button.ba-button-grey-3 {
  background: #adafb3;
  border-color: #adafb3;
}
a.ba-button-grey-3-warn,
button.ba-button-grey-3-warn {
  background: #adafb3;
  border-color: #adafb3;
}
a.ba-button-grey-3-warn:hover,
button.ba-button-grey-3-warn:hover {
  background: red;
  border-color: red;
}
a.ba-button-outline,
button.ba-button-outline {
  color: #1d90a1;
  border: 1px solid #1d90a1;
  background: transparent;
}

#okta-sign-in.auth-container input.button-primary {
  background: #1e91a2 !important;
  border-color: #1e91a2 !important;
}
#okta-sign-in.auth-container input.button-primary:hover,
a.ba-button:hover,
button.ba-button:hover,
a.ba-button-outline:hover,
button.ba-button-outline:hover {
  background: #1e91a2;
  border-color: #1e91a2;
  color: #fff;
}
.marketo-blue {
  color: rgb(81, 153, 194);
}
.text-red {
  color: red;
}

.width-45 {
  width: 45%;
}
